import React, { useState, FormEvent } from "react";
import Turnstile, { useTurnstile } from "react-turnstile";

// Define props for the TurnstileWidget
interface TurnstileWidgetProps {
  onVerify: (token: string | null) => void;
}

// Turnstile Widget Component with props type
function TurnstileWidget({ onVerify }: TurnstileWidgetProps) {
  const turnstile = useTurnstile();

  return (
    <Turnstile
      sitekey="3x00000000000000000000FF"
      onVerify={(token: string) => {
        onVerify(token);
      }}
      onExpire={() => {
        onVerify(null); // Clear the token on expiration
        turnstile.reset(); // Reset the Turnstile widget
      }}
    />
  );
}

// Webform Component
const Webform: React.FC = () => {
  const [token, setToken] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleVerify = (token: string | null) => {
    setToken(token);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!token) {
      alert("Please complete the Turnstile verification.");
      return;
    }

    setIsSubmitting(true);

    const form = event.currentTarget;
    const message = (form.elements.namedItem("message") as HTMLTextAreaElement)
      .value;

    try {
      // Make the fetch request to the backend, sending both message and Turnstile token
      const response = await fetch("/api/v2/player/webform-message/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ turnstile: token, message }),
      });

      if (!response.ok) {
        alert("Failed to submit. Please try again.");
        setToken(null); // Clear token on failure
      } else {
        alert("Message Sent successfully!");
        form.reset(); // Clear the form
        setIsSubmitting(false);
      }
    } catch (error) {
      alert("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={containerStyle}>
      <p style={titleStyle}>Interact with this show</p>
      <form onSubmit={handleSubmit} style={formStyle}>
        <label htmlFor="message">Message studio</label>
        <textarea id="message" name="message" required style={inputStyle} />

        <TurnstileWidget onVerify={handleVerify} />
        <button type="submit" disabled={!token || isSubmitting} style={buttonStyle}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

const titleStyle: React.CSSProperties = {
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginBottom: "1rem",
  justifyContent: "flex-start",
};

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "1rem",
  backgroundColor: "white",
  border: "1px solid #dae5e5",
  flex: "1 1 50%",
};

const formStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
};

const inputStyle: React.CSSProperties = {
  width: "100%",
  padding: "0.5rem",
  margin: "0.5rem",
  boxSizing: "border-box",
  marginLeft: 0,
};

const buttonStyle: React.CSSProperties = {
  padding: "0.5rem",
  margin: "0.5rem",
  backgroundColor: "#ee6600",
  color: "white",
  border: "none",
  cursor: "pointer",
  marginLeft: 0,

};

export default Webform;
