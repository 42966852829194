import exp from "constants";
import React from "react";

const Webcam = () => {
  return (
    <div style={containerStyle}>
      <img style={imageStyle} src="https://www.camfm.co.uk/media/webcam/live.png" alt="Webcam" />
    </div>
  );
};

const imageStyle: React.CSSProperties = {
  width: '100%',
};

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  padding: '16px',
  minWidth: '334px',
};

export default Webcam;