import React from "react";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import styled from 'styled-components';

interface SlideshowProps {
  image: string;
  title: string;
  subtitle: string;
  url: string;
}

interface SlideshowImagesProps {
  images: SlideshowProps[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  max-width: 50vw;
  width: 100%;
  overflow: hidden;
  height: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 100vw;
  }
`;

const slideWrapperStyle: React.CSSProperties = {
  maxWidth: '100%',
  width: '100%',
  overflow: 'hidden',
  border: '#ee6600 0.5px solid',
};

const slideStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '100%',
  width: '100%',
  height: '70vh',
  overflow: 'hidden',
  position: 'relative',
};

const imageStyle: React.CSSProperties = {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%', 
};

const SlideshowImages: React.FC<SlideshowImagesProps> = ({ images }) => {
  console.log(images);
  return (
    <Container>
      <div style={slideWrapperStyle}>
        <Slide
          canSwipe={false}
          indicators={false}
          autoplay={images.length > 1}
          arrows={images.length > 1}
        >
          {images.map((image, index) => (
            <div key={index} className="each-slide" style={slideStyle}>
              <div 
                style={{ 
                  ...imageStyle,
                  backgroundImage: `url(${image.image})`,
                }}
              >
                <span style={spanStyle} onClick={() => window.location.href = image.url}>
                  <p style={titleStyle}>{image.title}</p>
                </span>
              </div>
            </div>
          ))}
        </Slide>
      </div>
    </Container>
  );
}

const spanStyle: React.CSSProperties = {
  position: 'absolute',
  bottom: '0px',
  left: '0px',
  color: '#fff',
  backgroundColor: '#006666',
  width: '100%',
  height: '50px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  cursor: 'pointer',
};

const titleStyle: React.CSSProperties = {
  fontSize: '1.5em',
  fontWeight: 'bold',
  color: '#fff',
  padding: '0 12px',
};

export default SlideshowImages;
