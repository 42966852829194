import React, { useEffect } from 'react';

// Extend the Window interface to include adsbygoogle
declare global {
  interface Window {
    adsbygoogle: any;
  }
}

const MiniAdverts = () => {
  useEffect(() => {
    // Ensure the adsbygoogle script is triggered only when the component is mounted
    const pushAds = () => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error('Adsense error:', e);
      }
    };
    
    // Wait for layout before pushing the ad
    const timeout = setTimeout(() => {
      pushAds();
    }, 500); // A small delay may allow the layout to stabilize

    return () => clearTimeout(timeout); // Cleanup the timeout when component unmounts
  }, []);

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '300px' }}>
      <ins className="adsbygoogle"
           style={{ display: 'inline-block', width: '250px', height: '250px' }}
           data-ad-client="ca-pub-7421179998624572"
           data-ad-slot="2441145369"></ins>
    </div>
  );
};

export default MiniAdverts;