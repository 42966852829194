import React, { useEffect } from "react";
import { useSchedule } from "../hooks/ScheduleContext";

const WeekSelector = () => {
    const { weekSelected, setWeekSelected } = useSchedule();

    const handleWeekChange = (direction: number) => {
        const newWeek = new Date(weekSelected);
        newWeek.setDate(newWeek.getDate() + 7 * direction);
        const newWeekString = newWeek.toISOString().split('T')[0];
        setWeekSelected(newWeekString);
    };

    return (
        <div style={containerStyle}>
            <div style={buttonStyle} onClick={() => handleWeekChange(-1)}>Prev Week</div>
            <div style={buttonStyle} onClick={() => handleWeekChange(1)}>Next Week</div>
        </div>
    );
}

export default WeekSelector;

const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '1em',
    paddingBottom: '1em',
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
    cursor: 'pointer',
    textDecoration: 'none',
};

const buttonStyle: React.CSSProperties = {
    margin: 0,
    backgroundColor: '#1b7278',
    cursor: 'pointer',
    color: 'white',
    padding: '.85em 1em',
    lineHeight: '1.0',
    fontSize: '.9rem',
};