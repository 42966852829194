import React from "react";
import {GenreData} from "../../common/utils/getGenres";

interface GenreContextType {
    genres: GenreData[];
    setGenres: React.Dispatch<React.SetStateAction<GenreData[]>>;
    genreSelected: number;
    setGenreSelected: React.Dispatch<React.SetStateAction<number>>;
    }

const GenreContext = React.createContext<GenreContextType | undefined>(undefined);

export const useGenre = () => {
    const context = React.useContext(GenreContext);
    if (!context) {
        throw new Error("useGenre must be used within a GenreProvider");
    }
    return context;
};

interface GenreProviderProps {
    children: React.ReactNode;
}

export const GenreProvider: React.FC<GenreProviderProps> = ({ children }) => {
    const [genres, setGenres] = React.useState<GenreData[]>([]);
    const [genreSelected, setGenreSelected] = React.useState(0);

    const value: GenreContextType = {
        genres,
        setGenres,
        genreSelected,
        setGenreSelected,};

    return (
        <GenreContext.Provider value={value}>
            {children}
        </GenreContext.Provider>
    );
};

