import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import { useQuery, useQueryClient } from 'react-query';
import GetOnAir from '../../common/utils/getOnAir2';

const containerStyle: React.CSSProperties = {
 

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '300px',
  maxWidth: '300px',
  border: '1px solid #dae5e5',
  backgroundColor: 'white',
};

const controlsStyle: React.CSSProperties = {

  backgroundColor: '#1f6f74',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '300px',
};

const iconStyle: React.CSSProperties = {
  fontSize: '16px',
  cursor: 'pointer',
  color: 'white',
  padding: '12px',
};

const volumeSliderStyle: React.CSSProperties = {
  width: '100px',
};

const captionStyle: React.CSSProperties = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  right: '0',
  padding: '12px',
  color: 'white',
  fontSize: '16px',
  fontWeight: '700',
};

const MiniPlayer: React.FC = () => {
  // Stream URL for Cam FM
  const streamUrl = 'https://stream.camfm.co.uk/camfm';
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1); // Default volume is 100%
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const onAirQuery = useQuery('onAir', GetOnAir);
  const onAirData = onAirQuery.data;


  const artworkStyle: React.CSSProperties = {
    width: '100%',
    height: '300px',
    maxWidth: '300px',
    objectFit: 'cover',
    background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 60%, rgb(49, 128, 133) 85%, rgb(49, 128, 133)), 
      url("${onAirData?.current_artwork }") center center / cover no-repeat`,
    backgroundColor: 'white',
    position: 'relative',
    color: 'white',
  };



  // Function to toggle play/pause state
  const handleTogglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        //remove src to stop buffering
        audioRef.current.src = '';
      } else {
        audioRef.current.src = streamUrl;
        audioRef.current
          .play()
          .catch((error) => console.error('Error playing audio:', error));
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Function to handle volume change
  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  return (
    <div style={containerStyle}>
      <audio ref={audioRef} preload="none" src={streamUrl}>
        Your browser does not support the audio element.
      </audio>
      
      <div style={artworkStyle} aria-label="Now playing artwork">
      <div style={captionStyle}>{onAirData?.current_title ? onAirData.current_title : "Cam FM Selection"}</div>
      </div>
      <div style={controlsStyle}>
        <FontAwesomeIcon
          icon={isPlaying ? faPause : faPlay}
          style={iconStyle}
          onClick={handleTogglePlayPause}
          aria-label={isPlaying ? 'Pause' : 'Play'}
        />
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          aria-label="Volume"
          style={volumeSliderStyle}
        />
        <FontAwesomeIcon
          icon={faWindowRestore}
          style={iconStyle}
          aria-label="Open player"
          onClick={() => window.open('Player', 'PlayerWindow', 'width=700,height=800')}
        />
        <FontAwesomeIcon
          icon={faCommentDots}
          style={iconStyle}
          aria-label="Message the studio"
        />
      </div>
    </div>
  );
};

export default MiniPlayer;
