import React from "react";
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/img/camfm-white.svg";
import { Link } from 'react-router-dom'; 

const Footer = () => {
  const styles: { [key: string]: React.CSSProperties } = {
    footerContainer: {
      backgroundColor: "#3e3e3e",
      padding: "40px 20px",
      color: "#fff",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      gap: '20px',
    },
    column: {
      flex: "1",
      minWidth: "200px",
      display: "flex",
      flexDirection: "column",
    },
    logo: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "15px",
    },
    description: {
      fontSize: "0.9rem",
      lineHeight: "1.5",
      marginBottom: "15px",
    },
    nav: {
      listStyleType: "none",
      padding: "0",
      marginBottom: "15px",
    },
    navItem: {
      marginBottom: "10px",
    },
    navLink: {
      color: "#fff",
      textDecoration: "none",
      fontSize: "0.95rem",
      transition: "color 0.3s ease",
    },
    navLinkHover: {
      color: "#1abc9c", 
    },
    socialIcons: {
      display: "flex",
      gap: "15px",
      marginTop: "10px",
    },
    icon: {
      color: "#fff",
      fontSize: "1.2rem",
      transition: "color 0.3s ease",
      cursor: "pointer",
    },
    iconHover: {
      color: "#1abc9c", // Icon hover colour
    },
    copyright: {
      width: "100%",
      textAlign: "center",
      marginTop: "30px",
      fontSize: "0.85rem",
      borderTop: "1px solid rgba(255,255,255,0.2)",
      paddingTop: "15px",
    },
  };

  return (
    <footer style={styles.footerContainer}>
      <div style={styles.column}>
        <div><img src={logo} alt="CamFM Logo" style={{ width: "100px" }} /></div>
        <p style={styles.description}>
            The student radio station for Cambridge and Anglia Ruskin University.
                  </p>
        <div style={styles.socialIcons}>
          <a href="https://www.facebook.com/camfm972/" target="#" style={styles.icon}>
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://x.com/camfm972" target="#" style={styles.icon}>
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
          <a href="https://www.instagram.com/camfm972/" target="#" style={styles.icon}>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.linkedin.com/company/cam-fm/" target="#" style={styles.icon}>
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>

      <div style={styles.column}>
        <ul style={styles.nav}>
          <li style={styles.navItem}>
          <Link to="/about" style={styles.navLink}>
            About us
          </Link>
          </li>
          <li style={styles.navItem}>
          <Link to="/committee" style={styles.navLink}>
            Committee
          </Link>
          </li>
        </ul>
      </div>

      <div style={styles.column}>
        <ul style={styles.nav}>
          <li style={styles.navItem}>
          <Link to="/privacy" style={styles.navLink}>
            Privacy Policy
          </Link>
          </li>
          <li style={styles.navItem}>
            
          <Link to="/terms" style={styles.navLink}>
            Terms & Conditions
          </Link>
          </li>
        </ul>
      </div>

    <div style={styles.copyright}>
      © Cambridge and Anglia Ruskin Student Radio Ltd 2017-{new Date().getFullYear()}.
    </div>
    </footer>
  );
};

export default Footer;
