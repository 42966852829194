import React, { useEffect, useState } from 'react';
import { useSchedule } from "./hooks/ScheduleContext";
import DateSelector from './components/DateSelector';
import ScheduleListItem from './components/ScheduleListItem';
import GetSchedule from '../common/utils/getSchedule';
import { useQuery } from 'react-query';
import { Show } from './components/ScheduleListItem';
import WeekSelector from './components/WeekSelector';

const Schedule = () => {
  const { schedule, setSchedule, dateSelected, weekSelected } = useSchedule();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedDay, setSelectedDay] = useState<{ shows: Show[] }>({ shows: [] });

  // Fetch schedule data when weekSelected is set
  const scheduleQuery = useQuery(
    ['schedule', weekSelected],
    () => GetSchedule(`${weekSelected}/`),
    {
      enabled: !!weekSelected, // Only fetch when weekSelected is truthy
      onSuccess: (data) => {
        setSchedule(data);

        // Remove shows that have already ended if necessary
        // const updatedSchedule = { ...data };
        setLoading(false);
      },
      onError: () => {
        setError('Error fetching schedule data');
        setLoading(false);
      },
    }
  );

  useEffect(() => {
    // Set selected day based on dateSelected whenever schedule or dateSelected changes
    //if (schedule && dateSelected) {
      setSelectedDay(schedule[dateSelected] as unknown as { shows: Show[] });
    //}
  }, [dateSelected, schedule]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }



  return (
    <div>
      <h1>Schedule</h1>
      <DateSelector />
      <div>
        {selectedDay && selectedDay.shows.map((show: Show) => (
          <ScheduleListItem key={show.id} show={show} />
        ))}
      </div>
      <WeekSelector />
    </div>
  );
};

export default Schedule;
