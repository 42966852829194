import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const FlatPages = () => {
    const url = window.location.pathname;
    const [page, setPage] = useState<{ title: string; content: string } | null>(null);
    console.log('url:', url);

    useEffect(() => {
      if (url) {
        fetch(`api/v2/flatpages${url}/`)
          .then((response) => response.json())
          .then((data) => {
            console.log('Fetched data:', data);
            setPage(data);
          })
          .catch((error) => {
            console.error('Error fetching flatpage:', error, 'Data:', error.response ? error.response.data : 'No data');
          });
      }
    }, [url]);
  
    if (!page) {
      return <div>Loading...</div>;
    }
  
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: page.content }} />
      </div>
    );
  };

export default FlatPages;