import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/img/camfm.svg';

export interface ControlsProps {
  streamUrl: string;
  title: string;
  subtitle: string;
  live: boolean;
}

const Controls: React.FC<ControlsProps> = ({ streamUrl, title, subtitle, live }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const progressBar = useRef<HTMLDivElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1); 
  const [progress, setProgress] = useState(0);

  // Toggle play/pause state
  const handleTogglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        //remove src to stop buffering during live stream
        if (audioRef.current.duration === Infinity) audioRef.current.src = '';
      } else {
        if (audioRef.current.duration === Infinity) {
          audioRef.current.src = streamUrl;
          audioRef.current.load();
        }
        audioRef.current.play().catch((error) => console.error('Error playing audio:', error));
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Update progress bar
  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const duration = audioRef.current.duration;
      if (!isNaN(duration) && duration !== Infinity) {
        const newProgress = (audioRef.current.currentTime / duration) * 100;
        setProgress(newProgress);
      }
    }
  };

  // Handle scrubbing
  const handleScrub = (event: React.MouseEvent<HTMLDivElement>) => {
    if (audioRef.current && progressBar.current) {
      const scrubTime = (event.nativeEvent.offsetX / progressBar.current.offsetWidth) * audioRef.current.duration;
      audioRef.current.currentTime = scrubTime;
    }
  };

  // Handle volume change
  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.volume = volume;
      audio.addEventListener('timeupdate', handleTimeUpdate);
    }
    return () => {
      if (audio) {
        audio.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [volume]);

  return (
    <div>
      <audio ref={audioRef} preload="none" src={streamUrl}>
        Your browser does not support the audio element.
      </audio>
      <div style={nowPlayingStyle}>
        <div style={logoContainerStyle}>
        <img style={logoStyle} src={logo} alt="Cam FM logo" />
        </div>
        <div style={detailsContainerStyle}>
          <div>
          <p style={titleStyle}>{title + ' '} 
            <span style={linkStyle(live)}>{live ? 'Live' : 'Catch up'}</span>
          
            </p>
          </div>
          <p style={descriptionStyle}>{subtitle}</p>
        </div>
      </div>

      <div style={controlsStyle}>
        <FontAwesomeIcon
          icon={isPlaying ? faPause : faPlay}
          style={iconStyle}
          onClick={handleTogglePlayPause}
          aria-label={isPlaying ? 'Pause' : 'Play'}
        />
                <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          aria-label="Volume"
          style={volumeSliderStyle}
        />

        <div
          ref={progressBar}
          onClick={handleScrub}
          style={progressBarStyle}
          aria-label="Progress bar"
          role="slider"
          tabIndex={0}
        >
          <div
            style={{
              backgroundColor: 'white',
              height: '100%',
              width: `${progress}%`,
            }}
          />
        </div>

        <div>
            <p style={timeStyle}>
            {Math.floor((audioRef?.current?.currentTime ?? 0) / 60)}:
            {Math.floor((audioRef.current?.currentTime ?? 0) % 60).toString().padStart(2, '0')}  
             {/* / {Math.floor((audioRef.current?.duration ?? 0) / 60)}:
            {Math.floor((audioRef.current?.duration ?? 0) % 60).toString().padStart(2, '0')} */}
            </p>
        </div>

      </div>
    </div>
  );
};

const descriptionStyle: React.CSSProperties = {
  fontSize: '80%',
  color: '#124a4e',
  margin: '0',
  padding: '12px',
  //ellipsis overflow for long descriptions
  overflow: 'hidden',
  display: '-webkit-box',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  paddingLeft: 0,
};

const titleStyle: React.CSSProperties = {
  fontSize: '1.5625rem',
  color: '#124A4E',
  margin: '0',
  fontWeight: 700,

};

const logoContainerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  flex: '1 1 50%',
  padding: '12px',
};

const detailsContainerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  flex: '1 1 50%',
  flexDirection: 'column',
  padding: '12px',
};

const nowPlayingStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  flexDirection: 'row',
  backgroundColor: '#cedee7',
  
};

const logoStyle: React.CSSProperties = {
  width: '100px',
};

const timeStyle: React.CSSProperties = {
  fontSize: '0.75rem',
  color: 'white',
  margin: '0',
  fontWeight: 700,
  padding: '12px',
};

const controlsStyle: React.CSSProperties = {
  backgroundColor: '#1f6f74',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
};

const iconStyle: React.CSSProperties = {
  fontSize: '16px',
  cursor: 'pointer',
  color: 'white',
  padding: '12px',
};

const progressBarStyle: React.CSSProperties = {
  backgroundColor: 'rgba(255, 255, 255, .3)',
  width: '100%',
  cursor: 'pointer',
  height: '8px',
  margin: '0 12px',
};

const volumeSliderStyle: React.CSSProperties = {
  width: '100px',
};

const linkStyle = (live: boolean): React.CSSProperties => ({
  margin: 0,
  color: '#fefefe',
  cursor: 'pointer',
  backgroundColor: live ? '#1f6f74' : '#124a4e',
  padding: '.33333rem .5rem',
  lineHeight: '1.0',
  fontSize: '0.6rem',
  fontWeight: 700,
  display: 'inline-block',
  marginInlineStart: '0.5rem',
  position: 'relative',
  bottom: '0.5em',
});

const linksContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'end',
  gap: '1rem',
  width: '100%',
};


export default Controls;
