import React from "react";

export interface ScheduleData {
    date: string;
    shows: string[];
}

interface ScheduleContextType {
    schedule: ScheduleData[];
    setSchedule: React.Dispatch<React.SetStateAction<ScheduleData[]>>;
    dateSelected: number;
    setDateSelected: React.Dispatch<React.SetStateAction<number>>;
    weekSelected: string;
    setWeekSelected: React.Dispatch<React.SetStateAction<string>>;
    }

const ScheduleContext = React.createContext<ScheduleContextType | undefined>(undefined);

export const useSchedule = () => {
    const context = React.useContext(ScheduleContext);
    if (!context) {
        throw new Error("useSchedule must be used within a ScheduleProvider");
    }
    return context;
};

interface ScheduleProviderProps {
    children: React.ReactNode;
}

export const ScheduleProvider: React.FC<ScheduleProviderProps> = ({ children }) => {
    const [schedule, setSchedule] = React.useState<ScheduleData[]>([]);
    const [dateSelected, setDateSelected] = React.useState(0);

    const date = new Date();
    date.setDate(date.getDate() - 7);
    const week = date.toISOString().split('T')[0];
    const [weekSelected, setWeekSelected] = React.useState(week);

    const value: ScheduleContextType = {
        schedule,
        setSchedule,
        dateSelected,
        setDateSelected,
        weekSelected,
        setWeekSelected,
    };

    return (
        <ScheduleContext.Provider value={value}>
            {children}
        </ScheduleContext.Provider>
    );
};