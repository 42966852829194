import React from 'react';
import { useSchedule } from '../hooks/ScheduleContext';

const DateSelector = () => {
  const { schedule, dateSelected, setDateSelected } = useSchedule();

  // Map dates to create formatted dates array
  const dates = schedule.map((item, index) => ({
    key: index,
    weekday: new Intl.DateTimeFormat('en-GB', { weekday: 'short' }).format(new Date(item.date)),
    date: new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short' }).format(new Date(item.date)),
  }));

  return (
    <div style={divStyle}>
      {dates.map((date, index) => (
        <div
          key={index}
          onClick={() => setDateSelected(index)}
          style={index === dateSelected ? selectedStyle : unselectedStyle}
        >
          <p style={dayStyle}>{date.weekday}</p>
          <p style={dateStyle}>{date.date}</p>
        </div>
      ))}
    </div>
  );
};

// Styles
const divStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'start',
  gap: '1rem',
  border: '1px solid #dae5e5',
  cursor: 'pointer',
  backgroundColor: 'white',
};

const selectedStyle: React.CSSProperties = {
  backgroundColor: '#dae5e5',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const unselectedStyle: React.CSSProperties = {
  backgroundColor: 'white',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const dayStyle: React.CSSProperties = {
  margin: 0,
  fontWeight: 700,
  color: '#1b7278',
};

const dateStyle: React.CSSProperties = {
  margin: 0,
  fontWeight: 700,
  fontSize: 'small',
  color: '#8a8a8a',
};

export default DateSelector;
