import React, { CSSProperties } from "react";
import HeaderOnAir from "./HeaderOnAir";
import logo from "../../assets/img/camfm.svg";
import HeaderNavBar from "./HeaderNavBar";
import HeaderListenNow from "./HeaderListenNow";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
    <div style={headerStyle}>
      <div style={contentStyle}>
        <Link to="/">
      <img src={logo} style={imgStyle} alt="Cam FM logo" />
      </Link>
      <HeaderOnAir />
      </div>
    </div>
    <div style={navbarStyle}>
    <div style={navbarContentStyle}>
      <HeaderNavBar />
      <HeaderListenNow />
    </div>
    </div>
    </>
  );
};

const navbarStyle: CSSProperties = {
  backgroundColor: '#bdd3de',
};

const navbarContentStyle: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  maxWidth: "60em",
  marginTop: "0",
  marginBottom: "0",
  marginLeft: "auto",
  marginRight: "auto",
  backgroundColor: '#bdd3de',
};

const contentStyle: CSSProperties = {
  maxWidth: "60em",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  marginTop: "0",
  marginBottom: "0",
  marginLeft: "auto",
  marginRight: "auto",
  height: "100px",
  paddingLeft: "15px",
  paddingRight: "15px",
  paddingBottom: "15px",
  paddingTop: "15px",
};

//style
const headerStyle: CSSProperties = {
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: 'auto',
  backgroundColor: "#cedee7",
  width: "100%",
};

const imgStyle: CSSProperties = {
  height: "100px",
};

export default Header;