import React from "react";

interface ShowHistoryItemProps {
  show: any;
  alt: boolean;
}

const ShowHistoryItem: React.FC<ShowHistoryItemProps> = ({ show, alt }) => {
  return (
    <div style={containerStyle(alt)}>
      <p style={titleStyle}>{show.title}</p>
      <p style={timeStyle}>{new Date(show.start).toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/,/g, '')}</p>

    </div>
  );
}

const containerStyle = (alt: boolean): React.CSSProperties => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch', 
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: alt ? '#f5f5f5' : 'white',
  padding: '10px',
  border: '1px solid #dae5e5',
  boxSizing: 'border-box',
  height: '100%',
});

const titleStyle: React.CSSProperties = {
  fontSize: '100%',
  fontWeight: '700',
  color: '#1b7278',
  margin: '0',
};

const timeStyle: React.CSSProperties = {
  fontSize: '80%',
  color: '#124a4e',
  margin: '0',
};

export default ShowHistoryItem;