import React, { useEffect, useState } from 'react';
import GenreSelector from './components/GenreSelector';
import ShowsList from './components/ShowsList';

const Shows = () => {

  return (
    <div>
      <GenreSelector />
      <ShowsList />
    </div>
  );
};

export default Shows;