import React, { useEffect, useState } from 'react';
import { useGenre } from "../hooks/GenreContext";
import { getGenres } from '../../common/utils/getGenres';
import GetGenres from '../../common/utils/getGenres2';
import { useQuery, useQueryClient } from 'react-query';

const GenreSelector = () => {

  const { genres, setGenres, genreSelected, setGenreSelected } = useGenre();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const queryClient = useQueryClient();
  const genresQuery = useQuery('genres', GetGenres);

  useEffect(() => {
    if (genresQuery.data) {
      setGenres(genresQuery.data);

      const allGenre = {  title: 'All', url: "/api/show/list/all", slug: "all" };
      
      setGenres([allGenre, ...genresQuery.data]);

      setLoading(false);
      console.log(genresQuery.data);
    }
    if (genresQuery.error) {
      setError('Error fetching genres data');
      setLoading(false);
    }
  }
  , [genresQuery.data, genresQuery.error]);

  return (
    <div style={containerStyle}>
        {genres.map((genre, index) => (
          <div style={genreSelected == index ? selectedGenreStyle : unselectedGenreStyle} key={index} onClick = {() => setGenreSelected(index)}>
            <p style={genreStyle}>{genre.title}</p>
          </div>
        ))}
    </div>
  );
};

const selectedGenreStyle: React.CSSProperties = {
  backgroundColor: '#dae5e5',
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const unselectedGenreStyle: React.CSSProperties = {
  backgroundColor: 'white',
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const genreStyle: React.CSSProperties = {
  margin: 0,
  fontSize: '100%',
  fontWeight: 400,
  color: '#1b7278',
  padding: '.7rem 1rem',
  paddingTop: '0.25rem',
  paddingBottom: '0.25rem',
};

const containerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'start',
  border: '1px solid #dae5e5',
  cursor: 'pointer',
  backgroundColor: 'white',
  flexWrap: 'wrap',
};

export default GenreSelector;