import React from "react";

interface ScheduleItemProps {
  title: string;
  url: string;
  start: string;
  end: string;
  description: string;
}

const titleStyle: React.CSSProperties = {
  fontSize: '1.125rem',
  fontWeight: '700',
  color: '#1b7278',
  margin : '0',
};

const timeStyle: React.CSSProperties = {
  fontSize: '0.75rem',
  color: '#1b7278',
  margin : '0',
};

const titleWrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  width: '100%',
  padding: '.8rem',
  borderBottom: '1px solid #dae5e5',
  boxSizing: 'border-box', 
}

const containerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '300px',
  backgroundColor: 'white',

  border: '1px solid #dae5e5',
  borderTop: 'none',
  boxSizing: 'border-box', 
};

const MiniScheduleFutureItem: React.FC<ScheduleItemProps> = ({ title, url, start, end, description }) => {
  return (
    <div style={containerStyle}>
    <div style={titleWrapperStyle}>
      <p style={titleStyle}>{title}</p>
      <p style={timeStyle}>{new Intl.DateTimeFormat('en-GB', { weekday: 'short', hour: '2-digit', minute: '2-digit' }).format(new Date(start))} - {new Intl.DateTimeFormat('en-GB', { hour: '2-digit', minute: '2-digit' }).format(new Date(end))}</p>
    </div>
  </div>
  );
};

export default MiniScheduleFutureItem;