import React from "react";
import MiniSchedule from "./components/MiniSchedule";
import MiniAdverts from "./components/MiniAdverts";
import MiniPlayer from "./components/MiniPlayer";

const Minibar = () => {
    return (
        <div style={containerStyle}>
            <MiniPlayer />
            <MiniAdverts />
            <MiniSchedule />
        </div>
    );
};

const containerStyle: React.CSSProperties = {
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
};

export default Minibar;