import React from 'react';
import ShowsListItem from "./ShowsListItem";
import { useGenre } from "../hooks/GenreContext";
import { useQuery } from 'react-query';
import GetShows from '../../common/utils/getShows2';


const ShowsList = () => {
  const { genres, genreSelected } = useGenre();

  const showsQuery = useQuery(
    ['shows', genreSelected],
    () => GetShows(genres[genreSelected].slug),
    {
      enabled: genres && genres.length > 0 && genreSelected !== undefined,
    }
  );

  const shows = showsQuery.data || [];



  return (
    <div>
      {shows.map((show: any) => (
        <ShowsListItem key={show.id} show={show} />
      ))}
    </div>
  );
};

export default ShowsList;
