import React from "react";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ListenLive = () => {
    return (
        <a style={containerStyle} href="/player" >
            <FontAwesomeIcon icon={faPlayCircle} size="1x" color="#124a4e" />
            <p style={textStyle}>Listen Live</p>
        </a>
    );
}

const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.85em 1em',
    margin: 0,
    cursor: 'pointer',
    backgroundColor: '#acc7d6',
    textDecoration: 'none',
};

const textStyle: React.CSSProperties = {
    margin: 0,
    paddingLeft: '0.5rem',
    textDecoration: 'none',
    color: '#124a4e',
    fontSize: '16px',
    transition: 'color 0.3s ease',
    fontWeight: 700,
};

export default ListenLive;