import React from "react";
import ShowHistoryItem from "./ShowHistoryItem";
import { useQuery, useQueryClient } from 'react-query';
import GetSchedule from '../../common/utils/getSchedule';
import { Link } from "react-router-dom";

const ShowHistory = () => {
  const [selectedDay, setSelectedDay] = React.useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date.toISOString().split('T')[0]
  });
  const schedule = useQuery(['schedule', selectedDay], async () => {
    const data = await GetSchedule(selectedDay + '/');
    // sort by date descending
    return data.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
  });

  if (schedule.isLoading) {
    return <div>Loading...</div>;
  }



  let counter = 0; 

  return (
    <div>
      <div>
        <p>Week selector</p>
      </div>

      <div>
        {schedule.data.map((day: any) =>
          day.shows.map((show: any) => {
            const alt = counter % 2; // Calculate the alternating value (0 or 1)
            counter += 1; // Increment the counter after each show

            return (
                <a href={`/player/${show.listen_again_id}`} key={show.id} style={{textDecoration: 'none'}}>
                <ShowHistoryItem
                  key={show.id}
                  show={show}
                  alt={alt == 0} 
                />
                </a>
            );
          })
        )}
      </div>
    </div>
  );
};


export default ShowHistory;