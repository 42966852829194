import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import GetOnAir from '../utils/getOnAir2';

const HeaderOnAir: React.FC = () => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null>(null);

  const onAirQuery = useQuery('onAir', GetOnAir);
  const onAirData = onAirQuery.data;


  return (
    <div style={style}>
      <p style={nowStyle}>Now on air</p>
      {error && <p>{error}</p>}

        <div>
          <h3 style={titleStyle}>{onAirData?.current_title ? onAirData.current_title : 'Cam FM Selection'}</h3>
        </div>

      <p style={nextStyle}>Up next: {onAirData?.next_title} - {onAirData?.next_start ? new Intl.DateTimeFormat('en-GB', { weekday: 'short', hour: '2-digit', minute: '2-digit' }).format(new Date(onAirData.next_start)) : ''}</p>
    </div>
  );
};

const nowStyle: React.CSSProperties = {

  fontSize: '100%',
  margin: '0',
  padding: '0',
};

const nextStyle: React.CSSProperties = {
  fontSize: '80%',
  margin: '0',
  padding: '0',
  lineHeight: '20px',
};

const titleStyle: React.CSSProperties = {
  fontSize: '1.9375rem',
  margin: '0',
  padding: '0',
  marginBottom: '0.2rem',
  lineHeight: '1.3',
  fontWeight: '700',
};

const style: React.CSSProperties = {
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: 'auto',
  minWidth: '0',
  minHeight:'0',
  margin: '0',
  padding: '0',
  paddingBottom: '1rem',


};

export default HeaderOnAir;