import React, { createContext, useRef, useState, useContext, ReactNode } from 'react';

// Define the type for the context value
interface AudioContextType {
  audioRef: React.MutableRefObject<HTMLAudioElement | null>;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  volume: number;
  setVolume: React.Dispatch<React.SetStateAction<number>>;
}

// Create the context
const AudioContext = createContext<AudioContextType | undefined>(undefined);

// Custom hook to use the AudioContext
export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error('useAudio must be used within an AudioProvider');
  }
  return context;
};

// Define the provider props
interface AudioProviderProps {
  children: ReactNode;
}

// AudioProvider component
export const AudioProvider: React.FC<AudioProviderProps> = ({ children }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);

  // Define the value object
  const value: AudioContextType = {
    audioRef,
    isPlaying,
    setIsPlaying,
    volume,
    setVolume,
  };

  return (
    <AudioContext.Provider value={value}>
      {children}
    </AudioContext.Provider>
  );
};
