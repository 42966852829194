import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { useAuth0 } from "@auth0/auth0-react";

const HeaderNavBar = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [accessToken, setAccessToken] = useState<string | null>(null);



  const navbarStyle: React.CSSProperties = {
    padding: '0',
    margin: '0',
  };

  const navListStyle: React.CSSProperties = {
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap', 
    margin: 0,
    padding: 0,
  };

  const navItemStyle: React.CSSProperties = {
    padding: '0.7rem 1rem',
    position: 'relative',
  };

  const linkStyle: React.CSSProperties = {
    textDecoration: 'none',
    color: '#1b7278',
    fontSize: '16px',
    transition: 'color 0.3s ease',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
  };

  const triangleStyle: React.CSSProperties = {
    marginLeft: '5px', 
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #1b7278', 
  };

  const dropdownMenuStyle: React.CSSProperties = {
    display: 'none',
    position: 'absolute',
    top: '100%', 
    left: 0,
    backgroundColor: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    zIndex: 1,
  };

  const dropdownItemStyle: React.CSSProperties = {
    padding: '0.5rem 1rem',
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState(false);
  const [isGetInvolvedDropdownOpen, setIsGetInvolvedDropdownOpen] = useState(false);

  const handleMouseEnter = (dropdown: string) => {
   if (dropdown === 'about') {
     setIsAboutDropdownOpen(true);
   } else if (dropdown === 'getinvolved') {
      setIsGetInvolvedDropdownOpen(true);
    }
  };

  const handleMouseLeave = (dropdown: string) => {
    if (dropdown === 'about') {
      setIsAboutDropdownOpen(false);
    } else if (dropdown === 'getinvolved') {
      setIsGetInvolvedDropdownOpen(false);
    }
  };

  return (
    <div style={navbarStyle}>
      <ul style={navListStyle}>
        <li style={navItemStyle}>
          <Link to="/" style={linkStyle}>
            Home
          </Link>
        </li>
        <li style={navItemStyle}>
          <Link to="/shows" style={linkStyle}>
            Shows
          </Link>
        </li>
        <li style={navItemStyle}>
          <Link to="/schedule" style={linkStyle}>
            Schedule
          </Link>
        </li>

        <li
            style={navItemStyle}
            onMouseEnter={() => handleMouseEnter('about')}
            onMouseLeave={() => handleMouseLeave('about')}
          >
        <Link to="/about" style={linkStyle}>
            About
            <span style={triangleStyle}></span> {/* Triangle indicator */}
          </Link>
        <ul style={{ ...dropdownMenuStyle, display: isAboutDropdownOpen ? 'block' : 'none' }}>
        <li style={dropdownItemStyle}>
          <Link to="/contact" style={linkStyle}>
            Contact us
          </Link>
        </li>
        <li style={dropdownItemStyle}>
          <Link to="/committee" style={linkStyle}>
            Committee
          </Link>
        </li>
        <li style={dropdownItemStyle}>
          <Link to="/news" style={linkStyle}>
            News & Journalism
          </Link>
        </li>
        <li style={dropdownItemStyle}>
          <Link to="/history" style={linkStyle}>
            History
          </Link>
        </li>
        <li style={dropdownItemStyle}>
          <Link to="/awardsandalumni" style={linkStyle}>
            Awards and Alumni
          </Link>
        </li>
        <li style={dropdownItemStyle}>
          <Link to="/advertising" style={linkStyle}>
            Advertise on Cam FM
          </Link>
        </li>
        
          </ul>
        </li>
        <li
            style={navItemStyle}
            onMouseEnter={() => handleMouseEnter('getinvolved')}
            onMouseLeave={() => handleMouseLeave('getinvolved')}
          >
          <Link to="/getinvolved" style={linkStyle}>
            Get Involved
              <span style={triangleStyle}></span> {/* Triangle indicator */}
          </Link>
          <ul style={{ ...dropdownMenuStyle, display: isGetInvolvedDropdownOpen ? 'block' : 'none' }}>
          <li style={navItemStyle}>
            <a href="https://members.camfm.co.uk/" style={linkStyle}>
              Member Login
            </a>
          </li>
        </ul>
        </li>
        <li style={navItemStyle}>
          <Link to="/advertising" style={linkStyle}>
            Advertise on Cam FM
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default HeaderNavBar;
