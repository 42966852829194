import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import Header from './common/components/Header';
import Home from './home/Home';
import Schedule from './schedule/Schedule';
import Shows from './shows/Shows';
import Player from './player/Player';
import Minibar from './minibar/Minibar';
import './App.css';
import { AudioProvider } from './minibar/hooks/AudioContent';
import { ScheduleProvider } from './schedule/hooks/ScheduleContext';
import { GenreProvider } from './shows/hooks/GenreContext';
import NotFound from './common/components/NotFound';
import FlatPages from './flatpages/FlatPages';
import Footer from './common/components/Footer';

function Layout() {
  // Layout component that includes Header, Minibar, and Outlet for nested routes
  return (
    <div>
      <Header />
      <div className="body-container">
        <div className="main-content">
          <Outlet /> {/* This will render nested routes here */}
        </div>
        <div className="minibar">
          <Minibar />
        </div>
      </div>
      <Footer />
    </div>
  );
}

function App() {
  const [flatpages, setFlatpages] = useState<{ url: string; title: string }[]>([]);
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    // Fetch flatpages from the Django backend
    fetch('https://api.camfm.co.uk/v2/flatpages/')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch flatpages');
        }
        return response.json();
      })
      .then((data) => {
        setFlatpages(data);
        setLoading(false); // Set loading to false after data is loaded
      })
      .catch((error) => {
        console.error('Error fetching flatpages:', error);
        setLoading(false); // Ensure loading stops even if there's an error
      });
  }, []);

  return (
    <AudioProvider>
      <Router>
        <Routes>
          {/* Route for Player that stands alone */}
          <Route path="/player" element={<Player />} />
          <Route path="/player/*" element={<Player />} />

          {/* Main layout route including Header and Minibar */}
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route
              path="/schedule"
              element={
                <ScheduleProvider>
                  <Schedule />
                </ScheduleProvider>
              }
            />
            <Route
              path="/shows"
              element={
                <GenreProvider>
                  <Shows />
                </GenreProvider>
              }
            />
            {/* Dynamically created routes for flatpages */}
            {!loading &&
              flatpages.map((page) => (
                <Route key={page.url} path={`${page.url}`} element={<FlatPages />} />
              ))}
            {/* Fallback route for undefined paths */}
            <Route path="*" element={loading ? <div>Loading...</div> : <NotFound />} />
          </Route>
        </Routes>
      </Router>
    </AudioProvider>
  );
}

export default App;
