import React, { useEffect, useState } from 'react';
import MiniScheduleFutureItem from './MiniScheduleFutureItem';
import MiniScheduleNextItem from './MiniScheduleNextItem';
import { useQuery, useQueryClient } from 'react-query';
import GetSchedule from '../../common/utils/getSchedule2';


type Show = {
  title: string;
  url: string;
  start: string;
  end: string;
  description: string;
  thumb?: string;
  date: string; 
};

type ScheduleData = {
  date: string;
  shows: Show[];
};

const MiniSchedule = () => {
  const queryClient = useQueryClient();
  const [nextShow, setNextShow] = useState<Show | null>(null);
  const [futureShows, setFutureShows] = useState<Show[]>([]);
  const [error, setError] = useState<string | null>(null);

  const { data: scheduleData, isLoading, isError, error: queryError } = useQuery('schedule', GetSchedule);

  useEffect(() => {
    if (scheduleData) {
      const findNextShows = (data: ScheduleData[]) => {
        let allShows: Show[] = [];

        // Flatten all shows into a single array with proper dates
        data.forEach((day) => {
          day.shows.forEach((show: Show) => {
            allShows.push({ ...show, date: day.date });
          });
        });

        // Sort the shows by start time
        allShows.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());

        // Remove shows that have already ended
        allShows = allShows.filter((show) => new Date(show.end).getTime() > Date.now());

        setNextShow(allShows[0] ?? null);
        setFutureShows(allShows.slice(1, 5));
      };

      findNextShows(scheduleData);
    }
  }, [scheduleData]);

  // Handle error from useQuery
  useEffect(() => {
    if (isError && queryError instanceof Error) {
      setError(queryError.message);
    }
  }, [isError, queryError]);

  if (isLoading) return <p>Loading schedule...</p>;

  return (
    <div style={containerStyle}>
      <h1>Schedule</h1>
      <div>
        {nextShow ? (
          <MiniScheduleNextItem
            title={nextShow.title}
            url={nextShow.url}
            start={nextShow.start}
            end={nextShow.end}
            description={nextShow.description}
            artwork={nextShow.thumb ?? ''}
          />
        ) : (
          <p>No upcoming shows available.</p>
        )}
      </div>
      <div>
        {futureShows.map((show, index) => (
          <MiniScheduleFutureItem
            key={index}
            title={show.title}
            url={show.url}
            start={show.start}
            end={show.end}
            description={show.description}
          />
        ))}
      </div>
      {error && <p>Error: {error}</p>}
    </div>
  );
};

const containerStyle: React.CSSProperties = {
  padding: '12px',
};

export default MiniSchedule;
