import React from "react";
import { useQuery, useQueryClient } from "react-query";
import GetSlideshow from "../common/utils/getSlideshow";
import SlideshowImages from "./components/SlideshowImages";

const Home = () => {

  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useQuery("slideshow", GetSlideshow);

  return (
    <div>
      {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <div>Error fetching data</div>
      ) : (
        <SlideshowImages images={data} />
      )}
    </div>
  );
};

export default Home;