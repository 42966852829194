import React from "react";

export interface Show {
  id: number;
  title: string;
  description: string;
  thumb: string;
  start: string;
  end: string;
  genre: string;
  can_listen_again: boolean;
  bg_colour: string;
  fg_colour: string;
}

interface ScheduleListItemProps {
  show: Show;
}

const ScheduleListItem: React.FC<ScheduleListItemProps> = ({ show }) => {
  return (
    <div style={containerStyle}>
      <div style={leftWrapperStyle}>
        <div style={timeContainerStyle}>
          <p style={timeStyle}>{new Intl.DateTimeFormat('en-GB', { hour: "2-digit", minute: "2-digit", timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).format(new Date(show.start))}</p>
          <p style={genreStyle(show.fg_colour, show.bg_colour)}>{show.genre}</p>
        </div>

        <div style={imageContainerSyle}>
          <img style={imageStyle} src={show.thumb} alt={show.title} />
        </div>
      </div>

      <div style={detailsContainerStyle}>
        <p style={titleStyle}>{show.title}</p>
        <p style={descriptionStyle}>{show.description}</p>
        <div style={linksContainerStyle}>
          {show.can_listen_again ? <p style={linkStyle}>Listen Again</p> : null}
          <p style={linkStyle}>DETAILS</p>
        </div>
      </div>

    </div>
  );
};

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: 'white',
  padding: '1rem',
  border: '1px solid #dae5e5',
  boxSizing: 'border-box',
  height: '100%',
};

const leftWrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'start',
  justifyContent: 'start',
  flex: '1 1 50%', 
};

const timeContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'start',
  width: 'auto',
  flex: '0 0 auto',
};

const timeStyle: React.CSSProperties = {
  margin: 0,
  fontWeight: 400,
  color: '#8a8a8a',
  fontSize: '1.9375rem',
  marginBottom: '0.5rem',
};

const genreStyle = (fg: string, bg: string): React.CSSProperties => {
  return {
    margin: 0,
    color: fg,
    backgroundColor: bg,
    fontSize: '0.8rem',
    padding: '.33333rem .5rem',
  };
}

const imageContainerSyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: "100%",
  paddingLeft: '1rem',
  paddingRight: '1rem',
  backgroundColor: 'white',
};

const imageStyle: React.CSSProperties = {
  width: '100%',
  height: 'auto',
};

const detailsContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'start',
  flex: '1 1 50%', 
};

const titleStyle: React.CSSProperties = {
  margin: 0,
  fontWeight: 700,
  color: '#124a4e',
  fontSize: '1.5625rem',
  marginBottom: '0.2rem',
};

const descriptionStyle: React.CSSProperties = {
  margin: 0,
  color: '#124a4e',
  marginBottom: '1rem',
  fontSize: '80%',
  lineHeight: '1.6',
};

const linksContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'end',
  gap: '1rem',
  width: '100%',
};

const linkStyle: React.CSSProperties = {
  margin: 0,
  color: '#fefefe',
  cursor: 'pointer',
  backgroundColor: '#1b7278',
  padding: '.85em 1em',
  lineHeight: '1.0',
  fontSize: '0.6rem',
};




export default ScheduleListItem;