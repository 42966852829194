import React from "react";

interface ScheduleItemProps {
  title: string;
  url: string;
  start: string;
  end: string;
  description: string;
  artwork: string;
}

const containerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '300px',
  backgroundColor: 'white',

  border: '1px solid #dae5e5',
  boxSizing: 'border-box', 
};

const descriptionStyle: React.CSSProperties = {
  paddingLeft: '.8rem',
  paddingRight: '.8rem',
  fontSize: '0.75rem',
};

const imageStyle: React.CSSProperties = {
  width: '100%',
  height: 'auto',
  objectFit: 'contain', 
};

const descriptionImageWrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: "stretch",
  margin: "0 auto",
  width: "100%",
};

const descriptionContainerStyle: React.CSSProperties = {
  display: 'flex',
  flex: 2,
  padding: '0 12px',
  color: '#124a4e',
};

const imageContainerStyle: React.CSSProperties = {  
  display: 'flex',
  flex: 1,
  overflow: 'hidden', 
  alignItems: 'center',
  justifyContent: 'center',
};

const titleStyle: React.CSSProperties = {
  fontSize: '1.125rem',
  fontWeight: '700',
  color: '#1b7278',
  margin : '0',
};

const timeStyle: React.CSSProperties = {
  fontSize: '0.75rem',
  color: '#1b7278',
  margin : '0',
};

const titleWrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  width: '100%',
  padding: '.8rem',
  borderBottom: '1px solid #dae5e5',
  boxSizing: 'border-box', 
}

const MiniScheduleNextItem: React.FC<ScheduleItemProps> = ({ title, url, start, end, description, artwork }) => {
  return (
    <div style={containerStyle}>
      <div style={titleWrapperStyle}>
        <p style={titleStyle}>{title}</p>
        <p style={timeStyle}>{new Intl.DateTimeFormat('en-GB', { weekday: 'short', hour: '2-digit', minute: '2-digit' }).format(new Date(start))} - {new Intl.DateTimeFormat('en-GB', { hour: '2-digit', minute: '2-digit' }).format(new Date(end))}</p>
      </div>
      <div style={descriptionImageWrapperStyle}>
        <div style={imageContainerStyle}>
          <img style={imageStyle} src={artwork} alt={title} />
        </div>
        <div style={descriptionContainerStyle}>
          <p style={descriptionStyle}>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default MiniScheduleNextItem;
