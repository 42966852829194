import React from "react";
import { ShowData } from "../../common/utils/getShows";

interface ShowsListItemProps {
  show: ShowData;
}

const ShowsListItem: React.FC<ShowsListItemProps> = ({ show }) => {
  return (
    <div style={containerStyle}>
      <div style={imageContainerStyle}>
        <img style={imageStyle} src={show.artwork} alt={show.title} />
      </div>
      <div style={detailsContainerStyle}>
        <p style={titleStyle}>{show.title}</p>
        <p style={genreStyle(show.fg_colour, show.bg_colour)}>{show.genre}</p>
        <p style={descriptionStyle}>{show.description}</p>
        <div style={linksContainerStyle}>
          <p style={linkStyle}>DETAILS</p>
        </div>
      </div>
    </div>
  );
}

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  border: '1px solid #acc7d6',
  margin: '10px',
};

const imageContainerStyle: React.CSSProperties = {
  width: '90%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1 1 33%',
  backgroundColor: 'white',
};

const detailsContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  flex: '1 1 66%',
  padding: '.9375rem'
};

const imageStyle: React.CSSProperties = {
  width: '100%',
};

const titleStyle: React.CSSProperties = {
  fontSize: '1.5rem',
  color: '#1b7278',
  fontWeight: '700',
  margin: 0,
};

const descriptionStyle: React.CSSProperties = {
  fontSize: '100%',
  color: 'black',
  lineHeight: '1.6',
};

const genreStyle = (fg: string, bg: string): React.CSSProperties => {
  return {
    margin: 0,
    color: fg,
    backgroundColor: bg,
    fontSize: '0.8rem',
    padding: '.33333rem .5rem',
    width: 'fit-content',
  };
}

const linksContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'end',
  gap: '1rem',
  width: '100%',
};

const linkStyle: React.CSSProperties = {
  margin: 0,
  color: '#fefefe',
  cursor: 'pointer',
  backgroundColor: '#1b7278',
  padding: '.85em 1em',
  lineHeight: '1.0',
  fontSize: '0.6rem',
};

export default ShowsListItem;