import React from "react";
import Webform from "./components/Webform";
import ShowHistory from "./components/ShowHistory";
import Controls from "./components/Controls";
import { ControlsProps } from "./components/Controls";

import { useQuery } from 'react-query';
import GetTrackDetails from "../common/utils/getTrackDetails";
import GetOnAir from "../common/utils/getOnAir2";
import { url } from "inspector";
import ListenLive from "./components/ListenLive";
import Webcam from "./components/Webcam";
import styled from 'styled-components';

interface TrackDetails {
  audio: string;
  title: string;
  artist: string;
}

const Player = () => {
  // get url after /player/
  const urlPath = window.location.pathname.split("/player/")[1];
  const onAirQuery = useQuery('onAir', GetOnAir);
  const [controlsProps, setControlsProps] = React.useState<ControlsProps>({
    streamUrl: "https://stream.camfm.co.uk/camfm",
    title: "",
    subtitle: "",
    live: true,
  });

  const trackDetailsQuery = useQuery('trackDetails', () => GetTrackDetails(urlPath));


  React.useEffect(() => {
    if (urlPath === undefined || urlPath === null || urlPath === '' || urlPath === 'undefined') {
      setControlsProps({
        streamUrl: "https://stream.camfm.co.uk/camfm",
        title: onAirQuery.data?.current_title || "",
        subtitle: onAirQuery.data?.current_desc || "",
        live: true,
      });
    } else {
      setControlsProps({
        streamUrl: trackDetailsQuery.data?.audio[0] || "https://stream.camfm.co.uk/camfm",
        title: trackDetailsQuery.data?.title || "",
        subtitle: trackDetailsQuery.data?.description || "",
        live: false,
      });
    }
  }, [urlPath, onAirQuery.data, trackDetailsQuery.data]);

  return (
    <div>
      <Controls {...controlsProps} />
      <StyledContainer>
        <div style={halfContainerStyle}>
          <Webcam />
          <Webform />
        </div>
        <div style={halfContainerStyle}>
          <ListenLive />
          <ShowHistory /></div>
      </StyledContainer>
    </div>
  );
};

const halfContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '1rem',
  width: '100%',
};

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: '1rem',
 margin: '1rem',
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;
  margin: 1rem;

  @media (max-width: 768px) {
  flex-direction: column;
}

`;

export default Player;


