import React from "react";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HeaderNavBar = () => {
    return (
        <div style={containerStyle} >
            <FontAwesomeIcon icon={faPlayCircle} size="1x" color="#1b7278" />
            <p style={textStyle}>Listen Now</p>
        </div>
    );
}

const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.7rem 1rem',
    margin: 0,
    cursor: 'pointer',
};

const textStyle: React.CSSProperties = {
    margin: 0,
    paddingLeft: '0.5rem',
    textDecoration: 'none',
    color: '#1b7278',
    fontSize: '16px',
    transition: 'color 0.3s ease',
    fontWeight: 700,
};

export default HeaderNavBar;